import React, { Suspense, useEffect, useState } from 'react';
import './App.css';
import { Canvas, useThree } from '@react-three/fiber';
import { Bounds, Html, OrbitControls, useBounds } from '@react-three/drei';
import Model from './3d';
import { Reading, Sensor } from './Sensor';
import * as signalR from "@microsoft/signalr";
import { useSearchParams } from 'react-router-dom';

const API = `https://papi.alternard.com`;


function App() {
  const api = useBounds()
  const [searchParams] = useSearchParams();
  const [sensors, setSensors] = useState<Sensor[]>([]);
  const [readings, setReadings] = useState<Reading[]>([]);
  const [updatedReading, setUpdatedReading] = useState<Reading | null>(null);

  // const camera = useThree(state => state.camera);




  console.log('params', searchParams.toString());


  useEffect(() => {

    async function getSensors(deviceId: number | string, companyId: number | string) {
      const res = await fetch(`${API}/api/Sensors/company/${companyId}?deviceSensorId=${deviceId}`)
        .then((response) => response.json())
        .then((data) => data as Sensor[]);
      setSensors(res);

    }

    async function getReadings(deviceId: number | string) {
      const res = await fetch(`${API}/api/Readings/device/${deviceId}/last`)
        .then((response) => response.json())
        .then((data) => data as Reading[]);
      setReadings(res);

    }

    const deviceId = searchParams.get("deviceId") ?? 0;
    getSensors(deviceId, searchParams.get("companyId") ?? 0);
    getReadings(deviceId);

  }, [searchParams]);

  useEffect(() => {
    if (updatedReading) {
      const index = readings.filter(
        (x) => x.sensorId !== updatedReading.sensorId
      );
      const updated = [...index, updatedReading];
      setReadings(updated);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedReading]);


  useEffect(() => {

    const readingsConnection = sensors
      .map((x) => `Readings/sensor/${x.id}/last`);
    const groups = readingsConnection.join(",");
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(`${API}/realtimehub?group=${groups}`)
      .build();

    if (readingsConnection.length) {
      connection.start();
    }

    readingsConnection.forEach((reading) => {
      connection.on(reading, (message: Reading) => {
        setUpdatedReading(message);
      });
    });

    return () => {
      connection.stop();
    };


  }, [sensors]);


  return (
    <div className="App">
      <Canvas /*camera={{ fov: 70, position: [-316, 164, 621] }}*/ style={{ height: '550px' }} dpr={[1, 2]}>

        <Suspense fallback={<Html>loading..</Html>}>
          <Bounds fit clip observe margin={1.2}>
            {/* <SelectToZoom> */}
            <Model sensors={sensors} columns={parseInt(searchParams.get("columns") ?? '2')} readings={readings} afterRender={() => {
              if (api) {

                // api.refresh().fit()
              }
            }} />
            {/* </SelectToZoom> */}
          </Bounds>
        </Suspense>
        <OrbitControls makeDefault minPolarAngle={0} maxPolarAngle={Math.PI / 1.75} ></OrbitControls>

      </Canvas>
    </div>
  );
}

type Props = {
  children: string | JSX.Element | JSX.Element[]
}

// This component wraps children in a group with a click handler
// Clicking any object will refresh and fit bounds
function SelectToZoom({ children }: Props): JSX.Element {
  const api = useBounds()
  return (

    <group
      onClick={(e) => (e.stopPropagation(), e.delta <= 2 && api.refresh(e.object).fit())} onPointerMissed={(e) => e.button === 0 && api.refresh().fit()}
    >
      {children}
    </group>
  )
}


export default App;
