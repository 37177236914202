/*
  Auto-generated by Spline
*/

import { useEffect, useRef, useState } from 'react';
import useSpline from '@splinetool/r3f-spline'
import { Center, Html, OrbitControls, PerspectiveCamera, } from '@react-three/drei'
import * as THREE from 'three';
import { Reading, Sensor } from './Sensor';
import { fitCameraToCenteredObject } from './model_focus';
import { useThree } from '@react-three/fiber';

type Sensors = {
  sensors: Sensor[];
  columns: number;
  readings: Reading[];
  afterRender?: () => void | undefined;
};

export default function Model(props: Sensors) {

  const renderedBreakers = useRef<THREE.Group>(null);
  const camera = useRef<THREE.OrthographicCamera | undefined>();

  const startPosition = new THREE.Vector3(-112.05, 90, -37.57);
  const { nodes, materials } = useSpline('https://prod.spline.design/3-Pu5pTnZvuNSiah/scene.splinecode');
  // const camera = useThree(state => state.camera);


  const sensorPairs = props.sensors.reduce(
    (prev: Sensor[][], item) => {
      let group: Sensor[] = prev.pop() ?? [];
      if (group.length === props.columns) {
        prev.push(group);
        group = [];
      }
      group.push(item);
      prev.push(group);
      return prev;
    },
    [[]]
  );


  const lastReading = (sensorId: number) => props.readings.find((r) => r.sensorId === sensorId);

  useEffect(() => {


    console.log(renderedBreakers);
    const constant = 10.5;
    if (camera.current) {

      camera.current.position.x = camera.current.position.x * constant;
      camera.current.position.y = camera.current.position.y * constant;
      camera.current.position.z = camera.current.position.z * constant;
    }
    // camera.position.z = 300;
    // camera.lookAt(0, 0, 0);


  }, [renderedBreakers]);




  return (
    <>
      {/* <OrbitControls camera={camera.current} >
      </OrbitControls> */}



      <color attach="background" args={['#e7e7e7']} />
      <PerspectiveCamera
        name="1"
        ref={camera}
        makeDefault={true}
        far={100000}
        near={5}
        fov={45}
        position={[-100.47, 62.86, 900]}
        rotation={[0, 0, 0]}
      />
      <Center>

        <group ref={renderedBreakers}>
          {sensorPairs.map((pair, pairIndex) => pair.map((sensor, index) => (<Breaker key={sensor.id} sensor={sensor} nodes={nodes} position={startPosition} handleClick={() => { }}
            materials={materials} index={index} pairIndex={pairIndex} lastReading={lastReading(sensor.id)}
          ></Breaker>)))}
        </group>
      </Center>

      <group dispose={null}>


        <directionalLight
          name="Light Main"
          castShadow
          intensity={1}
          shadow-mapSize-width={1024}
          shadow-mapSize-height={1024}
          shadow-camera-near={-10000}
          shadow-camera-far={100000}
          shadow-camera-left={-500}
          shadow-camera-right={500}
          shadow-camera-top={500}
          shadow-camera-bottom={-500}
          position={[-129.8, 169.47, 157.37]}
        />
        <directionalLight
          name="Key Light"
          castShadow
          intensity={0.2}
          shadow-mapSize-width={1024}
          shadow-mapSize-height={1024}
          shadow-camera-near={-10000}
          shadow-camera-far={100000}
          shadow-camera-left={-500}
          shadow-camera-right={500}
          shadow-camera-top={500}
          shadow-camera-bottom={-500}
          position={[138.73, 169.47, -75.64]}
        />
        {/* <mesh
          name="Floor"
          geometry={nodes.Floor.geometry}
          material={materials['Floor Material']}
          castShadow
          receiveShadow
          rotation={[-Math.PI / 2, 0, 0]}
          scale={1}
        /> */}


        <hemisphereLight name="Default Ambient Light" intensity={0.75} color="#eaeaea" />
      </group>

    </>
  )
}


type BreakerProps = {
  sensor: Sensor;
  nodes: Record<string, any>;
  position: THREE.Vector3;
  handleClick: () => void;
  materials: Record<string, any>;
  index: number;
  pairIndex: number;
  lastReading: Reading | undefined;

};
const Breaker = (props: BreakerProps) => {

  const { index, pairIndex, sensor, lastReading, handleClick, materials, nodes } = props;

  const inputRef = useRef<THREE.Group>(null);

  const isOdd = index % 2 !== 0;
  const [color, setColor] = useState(new THREE.MeshBasicMaterial({ color: `#3E3737` }));
  const text = `${sensor.name}: ${lastReading?.value ?? '-'} ${sensor.sensorType.name}`;

  useEffect(() => {

    if (inputRef.current) {
      const verticalPosition = pairIndex * -90;
      const horizontalPosition = isOdd ? 400 * index + 10 : 0;

      if (!isOdd) {
        inputRef.current.rotation.z = 3.1415927;
      }

      inputRef.current.position.add(new THREE.Vector3(horizontalPosition, verticalPosition, 0));
      inputRef.current.position.add(new THREE.Vector3(0, 60, 0));

    }


  }, [index, isOdd, pairIndex]);


  useEffect(() => {

    if (lastReading !== undefined && lastReading.value >= sensor.max) {
      setColor(new THREE.MeshBasicMaterial({ color: `red` }));
    } else if (sensor.connected) {
      setColor(new THREE.MeshBasicMaterial({ color: `green` }));
    }
  }, [lastReading, sensor.connected, sensor.max]);




  return <group ref={inputRef} name="Breaker">
    <group name="Tornillos" position={[119.11, -23.05, -80.86]}>
      <mesh
        name="screw"
        geometry={nodes.screw.geometry}
        material={nodes.screw.material}
        castShadow
        receiveShadow
        position={[23.08, 21.98, -11.26]}
        rotation={[1.57, 0, 0]}
        scale={2.46} />
      <mesh
        name="screw1"
        geometry={nodes.screw1.geometry}
        material={nodes.screw1.material}
        castShadow
        receiveShadow
        position={[-263.06, 21.98, -11.26]}
        rotation={[1.57, 0, 0]}
        scale={2.46} />
    </group>
    <group name="Boton" position={[7.87, -2.78, 90.65]}>
      <mesh
        name="Cylinder"
        geometry={nodes.Cylinder.geometry}
        material={color}
        castShadow
        receiveShadow
        position={[-11.18, -0.01, -5.86]}
        rotation={[3.14, 0.02, 0]}
        scale={1}
        onClick={handleClick} />
      <mesh
        name="Cube 2"
        geometry={nodes['Cube 2'].geometry}
        material={color}
        castShadow
        receiveShadow
        position={[13.39, 0, 9.57]}
        rotation={[0, -0.48, 0]} />
    </group>
    <mesh
      name="Cube 3"
      geometry={nodes['Cube 3'].geometry}
      material={materials['Cube 3 Material']}
      castShadow
      receiveShadow
      position={[-0.17, 0, -92.02]}
      rotation={[-Math.PI / 2, 0, -0.02]}
      scale={[1.12, 1, 0.26]} />
    <mesh
      name="Cube"
      geometry={nodes.Cube.geometry}
      material={materials['Cube Material']}
      castShadow
      receiveShadow
      position={[-6.99, 0, 55.66]}
      rotation={[-Math.PI / 2, 0, -0.01]}
      scale={[1.12, 1, 0.26]} />

    <Text content={text} position={isOdd ? new THREE.Vector3(200, 0, 60) : new THREE.Vector3(450, -20, 60)} isOdd={isOdd} ></Text>
  </group >;
};

type TextProps = {
  content: string;
  position: THREE.Vector3 | undefined;
  isOdd: boolean;
}

const Text = (props: TextProps,): JSX.Element => {
  const { content, position, isOdd } = props;
  const meshMat = useRef<THREE.MeshStandardMaterial | null>(null);
  const transform = { transform: `translate3d(calc(50 % ${isOdd ? '+' : '-'} 80px), 0, 0)`, };



  // const before = {
  //   '&::before': {
  //     content: '',
  //     position: 'absolute',
  //     top: '25px',
  //     left: '40px',
  //     height: '1px',
  //     width: '40px',
  //     background: 'black'
  //   }
  // };


  return (
    <mesh position={position}  >
      <meshStandardMaterial ref={meshMat} roughness={0.75} emissive="#404057" />
      <Html >
        <div className="content" style={transform}>
          {content}
        </div>
      </Html>
    </mesh>
  )
}


